import React from 'react'
import ModalControl from './ModalControl'

const ModalContext = (props) => {

    const { children } = props

    return (
        <ModalControl.Context.Provider value={ModalControl.Instance}>
            {children}
        </ModalControl.Context.Provider>
    )

}

export default ModalContext