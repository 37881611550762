import React from 'react'
import FileFolder from '../models/FileFolder'

const uncategorized = new FileFolder({
    id: null,
    name: 'Uncategorized',
    createdAt: new Date().getTime()
})

/**
 * @callback HandleActionCallback
 * @param {React.MouseEvent<HTMLElement,MouseEvent>} e
 * @param {string} actionId
 * @param {any} payload
 */

/**
 * @typedef FileFoldersProps
 * @property {boolean} readonly
 * @property {Array<FileFolder>} list
 * @property {string | null} selectedId
 * @property {HandleActionCallback} handleAction
 */

/** @param {FileFoldersProps} props */
const FileFolders = (props) => {

    const {
        readonly = false,
        list = [],
        selectedId = null,
        handleAction = () => {}
    } = props

    const folder = list.find(folder => folder.id === selectedId) || null
    const folders = list.filter(folder => folder.id !== selectedId)
    if (folder !== null) {
        folders.unshift(uncategorized)
    }

    let name = folder ? folder.name : '〰️'
    name = name.length === 0 ? '#' : name

    const dropdownClasses = [
        'dropdown-menu',
        'dropdown-menu--small'
    ]
    if (folders.length === 0) {
        dropdownClasses.push('dropdown-menu--hidden')
    }

    return (
        <div className="component file-folders">
            <span className="badge" data-bs-toggle="dropdown" aria-expanded="false">{name}</span>
            <ul className={dropdownClasses.join(' ')}>
            {folders.map(folder => (
                <li key={folder.id}>
                    <a className="dropdown-item" onClick={e => handleAction(e, 'update_folder', folder.id)}>
                        {folder.name || '[No name]'}
                    </a>
                </li>
            ))}
        </ul>
        </div>
    )

}

export default FileFolders