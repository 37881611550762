import logging from '@toolcase/logging'
import { generateId } from '@toolcase/base'
import networkThrottle from '../helpers/networkThrottle'
import Storage from '../helpers/Storage'
import FileItem from '../models/FileItem'
import FileTag from '../models/FileTag'
import FileCategory from '../models/FileFolder'
import updateObject from '../helpers/updateObject'
import { supportedFileFormats } from '../config'

class FileService {

    /**
     * @private
     * @type {Storage<Array<FileItem>>}
     */
    queued = new Storage([])

    /**
     * @private
     * @type {Map<string,File>}
     */
    queuedFiles = new Map()

    /** @private */
    logger = logging.getLogger('service=files')

    async fetch(projectId) {
        this.logger.info(`#fetch(${projectId}) invoked`)

        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
        /** @type {Storage<Array<FileCategory>>} */
        const categories = Storage.getStorage('file_categories', projectId)
        /** @type {Storage<Array<FileTag>>} */
        const tags = Storage.getStorage('file_tags', projectId)
        return {
            files: files.copy(),
            categories: categories.copy(),
            tags: tags.copy()
        }
    }

    getQueue() {
        return this.queued.copy()
    }

    hasQueuedFiles() {
        return this.queued.get().length > 0
    }

    /**
     * @param {Array<File>} files
     */
    enqueueFiles(files) {
        for (let file of files) {
            let isValid = this.isValidFile(file)
            if (!isValid) {
                this.logger.warning(`file ${file.name} can not be uploaded`)
                continue
            }

            
            let item = this.createFileItem(file)
            this.queued.get().push(item)
            this.queuedFiles.set(item.id, file)
        }
    }

    async uploadQueuedFile(projectId) {
        this.logger.info(`#uploadQueuedFile(${projectId}) invoked`)

        const item = this.queued.get()[0]
        const file = this.queuedFiles.get(item.id) || null
        
        let data = new FormData()
        data.append('file', file)

        // TODO: replace with backend call, send data as multipart/form-data
        await networkThrottle(file.size / (1000 * 10))
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
        files.get().push(item)
        files.persist()
        // AFTER CALL
        
        this.queued.get().shift()
        this.queuedFiles.delete(item.id)

    }

    /**
     * @param {string | Array<string>} id 
     */
    async deleteFiles(projectId, id) {
        this.logger.info(`#deleteFiles(${projectId}, ${id}) invoked`)
        let ids = Array.isArray(id) ? id : [ id ]
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
        let filtered = files.get().filter(file => {
            return !ids.includes(file.id)
        })
        files.set(filtered)
        files.persist()
    }

    /**
     * @param {string | Array<string>} id 
     * @param {Partial<File>} data 
     */
    async updateFiles(projectId, id, data) {
        this.logger.info(`#updateFiles(${projectId}, ${id}, ${JSON.stringify(data)}) invoked`)
        let ids = Array.isArray(id) ? id : [ id ]
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
        let filesForUpdate = files.get().filter(file => {
            return ids.includes(file.id)
        })
        for (let file of filesForUpdate) {
            updateObject(file, data)
        }
        files.persist()
    }

    /**
     * @param {string} label 
     */
    async createCategory(projectId, name) {
        this.logger.info(`#createCategory(${projectId}, ${name}) invoked`)
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileCategory>>} */
        const categories = Storage.getStorage('file_categories', projectId)
        categories.get().push(new FileCategory({
            id: generateId(12),
            name: name,
            createdAt: new Date().toISOString()
        }))
        categories.persist()
    }

    /**
     * @param {string} id 
     */
    async deleteCategories(projectId, id) {
        this.logger.info(`#deleteCategories(${projectId}, ${id}) invoked`)
        let ids = Array.isArray(id) ? id : [ id ]
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
	    /** @type {Storage<Array<FileCategory>>} */
        const categories = Storage.getStorage('file_categories', projectId)

        let filesForUpdate = files.get().filter(file => ids.includes(file.categoryId))
        for (let file of filesForUpdate) {
            file.categoryId = null
        }

        let filtered = categories.get().filter(cateogry => !ids.includes(cateogry.id))
        categories.set(filtered)
        
        files.persist()
        categories.persist()
    }

    /**
     * @param {string | Array<string>} id 
     * @param {Partial<Category>} data 
     */
    async updateCategories(projectId, id, data) {
        this.logger.info(`#updateCategories(${projectId}, ${id}, ${JSON.stringify(data)}) invoked`)
        let ids = Array.isArray(id) ? id : [ id ]
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileCategory>>} */
        const categories = Storage.getStorage('file_categories', projectId)
        let updateList = categories.get().filter(category => {
            return ids.includes(category.id)
        })
        for (let category of updateList) {
            updateObject(category, data)
        }
        categories.persist()
    }

    /**
     * @param {string} name
     * @param {string} refId 
     */
    async createTag(projectId, name, refId = null) {
        this.logger.info(`#createTag(${projectId}, ${name}, ${refId}) invoked`)
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
        /** @type {Storage<Array<FileTag>>} */
        const tags = Storage.getStorage('file_tags', projectId)
        let id = generateId(12)
        tags.get().push(new FileTag({
            id: id,
            name: name,
            createdAt: new Date().toISOString()
        }))
        tags.persist()        

        if (refId === null) {
            return
        }
        let file = files.get().find(file => file.id === refId) || null
        if (file === null) {
            return
        }
        updateObject(file, {
            tagIds: [...file.tagIds, id]
        })
        files.persist()
    }

    /**
     * @param {string} id 
     */
    async deleteTags(projectId, id) {
        this.logger.info(`#deleteTags(${projectId}, ${id}) invoked`)
        let ids = Array.isArray(id) ? id : [ id ]
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileItem>>} */
        const files = Storage.getStorage('files', projectId)
        let filesForUpdate = files.get().filter(file => {
            for (let tagId of file.tagIds) {
                if (ids.includes(tagId)) {
                    return true
                }
            }
            return false
        })

        for (let file of filesForUpdate) {
            for (let tagId of ids) {
                let tagIndex = file.tagIds.findIndex(id => id === tagId)
                if (tagIndex === -1) {
                    continue
                }
                file.tagIds.splice(tagIndex, 1)
            }
        }
        
        files.persist()
    }

    /**
     * @param {string | Array<string>} id 
     * @param {Partial<Category>} data 
     */
    async updateTags(projectId, id, data) {
        this.logger.info(`#updateTags(${projectId}, ${id}, ${JSON.stringify(data)}) invoked`)
        let ids = Array.isArray(id) ? id : [ id ]
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<FileTag>>} */
        const tags = Storage.getStorage('file_tags', projectId)
        let updateList = tags.get().filter(tag => {
            return ids.includes(tag.id)
        })
        for (let tag of updateList) {
            updateObject(tag, data)
        }
        tags.persist()
    }

    /**
     * @private
     * @param {File} file 
     */
    isValidFile(file) {
        const { type, extension } = this.getFileType(file)
        const format = supportedFileFormats.find(format => {
            return format.type === type && format.extension === extension && format.mime === file.type
        }) || null
        return format !== null
    }

    /**
     * @private
     * @param {File} file 
     */
    createFileItem(file) {
        const { type, extension } = this.getFileType(file)
        let nameParts = file.name.split('.')
        nameParts.pop()
        const item = new FileItem({
            id: generateId(12),
            createdAt: new Date().toISOString(),
            name: nameParts.join('.'),
            size: file.size,
            mime: file.type,
            type: type,
            extension: extension,
            childs: []
        })
        return item
    }

    /**
     * @private
     * @param {File} file 
     */
    getFileType(file) {
        let type = file.type.split('/')[0]
        let extension = file.name.split('.').pop()
        if (type === '') {
            type = 'binary'
        }
        return { type, extension }
    }

}

FileService.Instance = new FileService()

export default FileService
