import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { createStore } from './store'
import Root from './Root'

import WelcomePage from './pages/WelcomePage'
import FilesPage from './pages/FilesPage'
import NotFoundPage from './pages/NotFoundPage'
import CreateProjectPage from './pages/CreateProjectPage'
import PacksPage from './pages/PacksPage'
import { Modal } from '@webgame-cloud/react-components'

const Router = () => {
    
    const store = createStore()

    return (
        <StoreProvider store={store}>
            <Root>
                <Modal.ModalContext>
                    <BrowserRouter>
                        <Routes>
                            <Route path='/' element={<WelcomePage />} />
                            <Route path='/create' element={<CreateProjectPage />} />
                            <Route path='/files' element={<FilesPage />} />
                            <Route path='/packs' element={<PacksPage />} />
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </BrowserRouter>
                </Modal.ModalContext>
            </Root>
        </StoreProvider>
    )
}

export default Router