import { createSlice } from '@reduxjs/toolkit'
import { getState, selectors } from './state'
import * as async from './actions'

const files = createSlice({
    name: 'files',
    initialState: getState,
    reducers: {
        
    },
    extraReducers: builder => {

        builder.addCase(async.fetchFiles.fulfilled, (state, action) => {
            const { files = [], categories = [], tags = [] } = action.payload
            state.list = files
            state.categories = categories
            state.tags = tags
        })
        
        builder.addCase(async.fetchFiles.rejected, (state, action) => {
            console.error(action.error)
        })

        builder.addCase(async.enqueueFiles.fulfilled, (state, action) => {
            state.totalQueuedFiles += action.payload
        })
        

        builder.addCase(async.uploadFiles.pending, state => {
            state.totalQueuedFiles = 0
        })

        builder.addCase(async.uploadFiles.fulfilled, state => {
            state.totalQueuedFiles = null
        })

        builder.addCase(async.getQueue.fulfilled, (state, action) => {
            state.queued = action.payload
        })

    }
})

const actions = {
    ...async,
    ...files.actions
}

export {
    selectors as fileSelectors,
    actions as fileActions
}

export default files