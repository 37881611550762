import updateObject from '../helpers/updateObject'

class Project {

    /** @param {Partial<Project>} data  */
    constructor(data) {
        updateObject(this, data)
    }

    /** @type {string} */
    id = null

    /** @type {string} */
    name = null

    /** @type {string} */
    color = null

    /** @type {string} */
    icon = null

}

export default Project