import React, { useContext } from 'react'
import useCurrentModal from './useCurrentModal'
import ModalControl from './ModalControl'

/**
 * @typedef ModalRenderProps
 * @property {string} className
 */

/**
 * @param {ModalRenderProps} props 
 */
const ModalRender = (props) => {

    const { children, className = '' } = props

    const modalControl = useContext(ModalControl.Context)
    const current = useCurrentModal()


    const list = Array.isArray(children) ? children : [ children ]
    const modal = list.find(element => element.key === current) || null

    const handleClose = () => modalControl.closeCurrent()

    const classes = [
        ...className.split(' '),
        'modals',
        'modals__render'
    ]
    if (current === null) {
        classes.push('modals__render--closed')
    }

    return (
        <ModalControl.ModalContext.Provider value={current}>
            <div onClick={handleClose} className={classes.join(' ')}>
                {modal}
            </div>
        </ModalControl.ModalContext.Provider>
    )
}

export default ModalRender