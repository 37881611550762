import { useContext } from 'react'
import ModalControl from './ModalControl'

/**
 * @template Payload
 * @template Input
 * @param {string} key
 * @param {import('./ModalControl').HandlePayload<Payload>} handlePayload
 */
function useModalOpen(key, handlePayload) {

    const modalControl = useContext(ModalControl.Context)

    /**
     * @param {Input} input 
     */
    const openModal = (input = null) => {
        return modalControl.open(key, handlePayload, input)
    }

    return openModal
}

export default useModalOpen