import updateObject from '../helpers/updateObject'

/**
 * @typedef TextureConfig
 * @property {number} width
 * @property {number} height
 * @property {number} optLevel
 */

/**
 * @typedef AudioConfig
 * @property {number} optLevel
 */

class Pack {

    /** @type {string} */
    id = null

    /** @type {string} */
    name = null

    /** @type {Array<string>} */
    categoryIds = []

    /** @type {Array<string>} */
    includeTagIds = []

    /** @type {Array<string>} */
    excludeTagIds = []

    /** @type {TextureConfig} */
    textures = {}

    /** @type {AudioConfig} */
    audio = {}

    /** @param {Partial<Pack>} data  */
    constructor(data) {
        updateObject(this, data)
    }


}

export default Pack