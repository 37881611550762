import Pack from '../../models/Pack'

export const getState = () => {

    return {

        /** @type {Array<Pack>} */
        list: []

    }
}

export const selectors = {
    /** @param {import('../index').State} state */
    allPacks: state => state.packs.list
}