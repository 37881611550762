
const config = {

    timeBetweenFetchRequests: 2000

}
export default config



export const menuItems = [
    { key: 'project', label: 'Project', path: null, items: [
        { key: 'files', label: 'Files', path: '/files', icon: 'bi bi-file-binary' },
        // { key: 'models', label: 'Models', path: '/models', icon: 'bi bi-braces' },
        { key: 'packs', label: 'Packs', path: '/packs', icon: 'bi bi-box-seam' },
    ]},
    { key: 'tools', label: 'Tools', path: null, items: [
        { key: 'font-editor', label: 'Font Editor', path: '/font-editor', icon: 'bi bi-fonts' },
        // { key: 'localization', label: 'Localization', path: '/localization', icon: 'bi bi-globe' },
        // { key: 'dialogues', label: 'Dialogues', path: '/dialogues', icon: 'bi bi-chat-left' },
        // { key: 'levels', label: 'Level Editor', path: '/levels', icon: 'bi bi-puzzle' }
    ]}
]
/**
 * @param {string} path
 */
export const getMenuItem = (path) => {
    for (let section of menuItems) {
        for (let item of section.items) {
            if (path === item.path) {
                return item
            }
        }
    }
    return null
}



export const supportedFileFormats = [
    { type: 'binary', extension: 'psd', mime: '' },
    { type: 'image', extension: 'jpg', mime: 'image/jpeg' },
    { type: 'image', extension: 'png', mime: 'image/png' },
    { type: 'audio', extension: 'mp3', mime: 'audio/mpeg' },
    { type: 'audio', extension: 'ogg', mime: 'audio/ogg' },
]

export const projectIcons = [
    'adventure',
    'board',
    'cooking',
    'crafting',
    'farm',
    'fighting',
    'gambling',
    'ghost',
    'hidden-object',
    'love',
    'medieval',
    'multiplayer',
    'ninja',
    'novels',
    'platform',
    'puzzle',
    'resources',
    'rithm',
    'rpg',
    'science',
    'defense',
    'shooter',
    'magic',
    'stealth',
    'survival'
]

export const colors = [
    'red',
    'orange',
    'yellow',
    'green',
    'cyan',
    'blue',
    'purple',
    'pink'
]