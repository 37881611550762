import FileCategory from '../../models/FileFolder'
import FileItem from '../../models/FileItem'
import FileTag from '../../models/FileTag'

export const getState = () => {

    return {

        /** @type {Array<FileCategory>} */
        categories: [],

        /** @type {Array<FileTag>} */
        tags: [],

        /** @type {Array<FileItem>} */
        list: [],

        /** @type {number} */
        totalQueuedFiles: null,

        /** @type {Array<FileItem>} */
        queued: []
    }

}


export const selectors = {

    /** @param {import('../index').State} state */
    files: state => state.files.list,

    /** @param {import('../index').State} state */
    categories: state => state.files.categories,

    /** @param {import('../index').State} state */
    tags: state => state.files.tags,

    /** @param {import('../index').State} state */
    queued: state => state.files.queued,

    /** @param {import('../index').State} state */
    isQueueActive: state => state.files.queuedFiles !== null,

    /** @param {import('../index').State} state */
    totalQueuedFiles: state => state.files.totalQueuedFiles

}