import { useContext, useEffect, useState } from 'react'
import ModalControl from './ModalControl'

/**
 * @param {string} key 
 */
function useCurrentModal() {

    const modalControl = useContext(ModalControl.Context)
    const [ changes, setChanges ] = useState(0)

    useEffect(() => {

        function handleChange() {
            setChanges(changes + 1)
        }

        modalControl.on('change', handleChange)
        return () => {
            modalControl.off('change', handleChange)
        }

    })
    const current = modalControl.current
    if (current === null) {
        return null
    }
    return current.key
}

export default useCurrentModal