import { formatByteSize } from '@toolcase/base'

/**
 * @typedef QueuedFileProps
 * @property {('unknown'|'image'|'audio'|'binary')} format
 * @property {string} extension
 * @property {string} name
 * @property {number} size
 * @property {boolean} active
 */



/**
 * 
 * @param {QueuedFileProps} props 
 */
const QueuedFile = (props) => {
    const {
        format,
        extension,
        name,
        size,
        active
    } = props

    return (
        <div className="component queued-file">
            <div className={`queued-file__type file-bg--${format}`}><span>{extension}</span></div>
            <div className="queued-file__label">
                {name}
            </div>
            <div className="queued-file__progress">
                {active ? (
                    <span className="icon icon--large icon--info animate animate--blink">
                        <i className="bi bi-cloud-arrow-up"></i>
                    </span>
                ) : (<></>)}
            </div>
            <div className="queued-file__size">{formatByteSize(size)}</div>
        </div>
    )
}

/**
 * TODO: add this icon and implement remove from queue
 * <span onClick={handleRemove} className="icon icon--large icon--danger icon--interactive">
 *     <i className="bi bi-x-circle"></i>
 * </span>
 * 
 */

export default QueuedFile