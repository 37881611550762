import React, { useState } from 'react'
import Logo from '../modules/Logo'
import MainMenu from '../modules/MainMenu'
import Header from '../modules/Header'
import ProjectMenu from '../modules/ProjectMenu'
import ProjectUsage from '../modules/ProjectUsage'
import Modals from '../Modals'

/**
 * @typedef DashboardLayoutProps
 * @property {string} pageTitle
 */

/**
 * @param {DashboardLayoutProps} props 
 */
const DashboardLayout = (props) => {

    const { children = [], pageTitle = '' } = props
    const [ isOpen, setOpen ] = useState(true)

    const handleToggle = () => setOpen(!isOpen)

    return (
        <section className="layout layout-dashboard">
            <Modals />
            <nav className={isOpen ? 'active' : ''}>
                <div onClick={handleToggle} className="layout-dashboard__toggle-button">
                    <i className="bi bi-list"></i>
                </div>
                <Logo />
                <ProjectMenu />
                <MainMenu />
                <ProjectUsage />
            </nav>
            <main>
                <Header pageTitle={pageTitle} onMenuToggle={handleToggle} />
                <div className="content">
                    <div className="container">
                        {children}
                    </div>
                </div>
            </main>
        </section>
    )

}

export default DashboardLayout