import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import CreateProject from '../modules/CreateProject'


const CreateProjectPage = () => {
    return (
        <DashboardLayout pageTitle="Create new project">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <CreateProject />
                </div>
            </div>
        </DashboardLayout>
    )
}

export default CreateProjectPage