/**
 * @template T
 * @param {Array<T>} entries 
 */
const toRows = (entries, entriesPerRow = 3) => {

    /** @type {Array<Array<T>>} */
    const rows = []
    let current = []
    for (let entry of entries) {
        if (current.length >= entriesPerRow) {
            rows.push(current)
            current = []
        }
        current.push(entry)
    }
    rows.push(current)
    return rows
}

export default toRows