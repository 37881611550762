export const getState = () => {

    return {

        initialLoad: false

    }

}


export const selectors = {

    /** @param {import('../index').State} state */
    isLoaded: state => state.app.initialLoad
}