import { useContext } from 'react'
import ModalControl from './ModalControl'

/**
 * @template Input
 * @returns {Input}
 */
function useModalInput() {
    const key = useContext(ModalControl.ModalContext)
    if (key === null) {
        return null
    }
    const control = useContext(ModalControl.Context)
    return control.getInput(key)
}

export default useModalInput