import updateObject from '../helpers/updateObject'

class FileTag {
    
    /** @param {Partial<FileTag>} data  */
    constructor(data) {
        updateObject(this, data)
    }

    /** @type {string} */
    id = null

    /** @type {string} */
    createdAt = null

    /** @type {string} */
    name = null
}

export default FileTag