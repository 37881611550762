import React from 'react'
import File from '../components/File'

const FileList = () => {
    
    return (<>
        <File />
    </>)

}

export default FileList