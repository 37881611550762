import { createSlice } from '@reduxjs/toolkit'
import { getState, selectors } from './state'
import * as async from './actions'

const app = createSlice({
    name: 'app',
    initialState: getState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(async.initFetch.fulfilled, state => {
            state.initialLoad = true
        })
    }
})

const actions = {
    ...async,
    ...app.actions
}

export {
    selectors as appSelectors,
    actions as appActions
}

export default app