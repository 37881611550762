import { useContext } from 'react'
import ModalControl from './ModalControl'

/**
 * @template Payload
 * @param {string} key 
 */
function useModalClose(key = null) {

    if (key === null) {
        key = useContext(ModalControl.ModalContext)
    }

    if (key === null) {
        throw new Error('useModalClose must be invoked inside modal or modal key must be provided')
    }

    const modalControl = useContext(ModalControl.Context)

    /**
     * @param {Payload} payload 
     */
    const closeModal = (payload) => {
        return modalControl.close(key, payload)
    }
    return closeModal
}

export default useModalClose