import { useSelector } from 'react-redux'
import { packSelectors } from '../store/packs'
import toRows from '../helpers/toRows'
import PackItem from '../components/PackItem'
import { Modal } from '@webgame-cloud/react-components'

/**
 * @typedef PackListProps
 * @property {JSX.Element} first
 */

/**
 * 
 * @param {PackListProps} param0 
 */
const PackList = () => {

    

    let packs = useSelector(packSelectors.allPacks)
    packs = [null, ...packs]
    const rows = toRows(packs)


    const onCreatePack = (payload) => {
        if (payload === null) {
            return
        }
        console.log('here ?', payload)
    }
    const openCreateModal = Modal.useModalOpen('create-pack', onCreatePack)

    return (
        <div className="module pack-list">
            {rows.map((row, index) => (
                <div key={index} className="row">
                    {row.map((entry, index) => (
                        <div key={index} className="col-lg-4 col-md-6">
                            {entry === null ? (
                                <PackItem.Empty onClick={() => openCreateModal()} text="+" />
                            ) : (
                                <PackItem />
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )

}

export default PackList