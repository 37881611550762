import {  createAsyncThunk } from '@reduxjs/toolkit'
import ProjectService from '../../services/ProjectService'

export const fetchProjects = createAsyncThunk(
    'projects/fetch',
    async (_) => {
        const payload = await ProjectService.Instance.fetch()
        return payload
    }
)

export const selectProject = createAsyncThunk(
    'projects/select',
    async (args = {}, { dispatch }) => {
        const { id } = args
        await ProjectService.Instance.selectProject(id)
        await dispatch(fetchProjects())
    }
)

export const createProject = createAsyncThunk(
    'projects/create',
    async (args = {}, { dispatch }) => {
        const { name = null, purpose = null, color = null, icon = null } = args
        // TODO: do someting with purpose property
        await ProjectService.Instance.createProject(name, color, icon)
        dispatch(fetchProjects())
    }
)