import { Broadcast } from '@toolcase/base'
import { createContext } from 'react'

/**
 * @typedef ModalDef
 * @property {string} key
 * @property {HandlePayload} handlePayload
 * @property {any} input
 */

/**
 * @typedef EventTypes
 * @type {('change')}
 */

/**
 * @callback CallbackFn
 * @param {string} key
 * @return {void}
 */

/**
 * @template T
 * @callback HandlePayload
 * @param {T | null} payload
 * @return {void}
 */

/**
 * @extends {Broadcast<EventTypes,CallbackFn,any>}
 */
class ModalControl extends Broadcast {

    /**
     * @private
     * @type {Array<ModalDef>}
     */
    defs = []

    constructor() {
        super()
    }

    get current() {
        if (this.defs.length === 0) {
            return null
        }
        let current = this.defs[this.defs.length - 1]
        return current
    }

    /**
     * @template T
     * @template I
     * @param {string} key 
     * @param {HandlePayload<T>} handlePayload 
     * @param {I} input
     */
    open(key, handlePayload = () => {}, input = null) {
        
        let existingDef = this.defs.find(def => def.key === key) || null
        if (existingDef !== null) {
            return false
        }

        this.defs.push({
            key,
            handlePayload,
            input
        })

        this.emit('change', key)
        return true
    }

    /**
     * @template T
     * @param {string} key 
     * @param {T} payload 
     */
    close(key, payload = null) {
        let defIndex = this.defs.findIndex(def => def.key === key)
        if (defIndex === -1) {
            return false
        }
        let def = this.defs[defIndex]
        def.handlePayload(payload)
        this.defs.splice(defIndex, 1)
        this.emit('change', key)
        return true
    }

    closeCurrent() {
        if (this.current === null) {
            return
        }
        this.close(this.current.key)
    }

    /**
     * @param {string} key 
     */
    getInput(key) {
        if (this.current === null) {
            return null
        }
        return this.current.input
    }

}

ModalControl.Instance = new ModalControl()

ModalControl.Context = createContext(ModalControl.Instance)
ModalControl.ModalContext = createContext()

export default ModalControl