import React from 'react'
import { useLocation } from 'react-router-dom'
import { getMenuItem, menuItems } from '../config'

const Header = (props) => {
    
    const { onMenuToggle, pageTitle = '' } = props
    
    const location = useLocation()

    let menuItem = getMenuItem(location.pathname)

    let title = menuItem ? menuItem.label : pageTitle

    return (
        <div className="module header">
            <div className="header__container">
                <div onClick={onMenuToggle} className="header__toggle-button">
                    <i className="bi bi-list"></i>
                </div>
                <div className="header__breadcrumb">
                    {/* <div className="header-breadcrumb__location">hellooo / My Page</div> */}
                    <h1 className="header-breadcrumb__title">{title}</h1>
                    
                </div>
            </div>
        </div>
    )
}

export default Header