import logging from '@toolcase/logging'
import { generateId } from '@toolcase/base'
import Storage from '../helpers/Storage'
import Project from '../models/Project'
import networkThrottle from '../helpers/networkThrottle'

class ProjectService {

    /** @private */
    logger = logging.getLogger('service=projects')

    /** @private */
    selectedProject = null

    async fetch() {
        this.logger.info('fetch project list')
        
        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<Project>>} */
        const projects = Storage.getStorage('projects')
        const projectList = projects.copy()
        if (projectList.length > 0 && this.selectedProject === null) {
            this.selectedProject = projectList[0].id
        }
        return {
            list: projectList,
            current: `${this.selectedProject}`
        }
    }

    async selectProject(id) {
        this.logger.info(`#selectProject(${id})`)

        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<Project>>} */
        this.selectedProject = `${id}`
    }

    async createProject(name, color, icon) {
        this.logger.info(`create project ProjectService#create(${name}, ${color}, ${icon})`)

        // TODO: replace with backend call
        await networkThrottle()
        /** @type {Storage<Array<Project>>} */
        const projects = Storage.getStorage('projects')
        projects.get().push(new Project({
            id: generateId(12),
            name: name,
            color: color,
            icon: icon
        }))
        projects.persist()
    }

    async updateProject(id, data) {

    }

    async archiveProject(id, clean) {

    }

}

ProjectService.Instance = new ProjectService()

export default ProjectService