import { createSlice } from '@reduxjs/toolkit'
import { getState, selectors } from './state'
import * as async from './actions'

const packs = createSlice({
    name: 'packs',
    initialState: getState,
    reducers: {
        
    },
    extraReducers: builder => {

    }
})

const actions = {
    ...async,
    ...packs.actions
}

export {
    selectors as packSelectors,
    actions as packActions
}

export default packs