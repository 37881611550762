
/** @template T */
class Storage {

    /**
     * @private
     * @type {T}
     */
    data = null

    /**
     * @private
     * @readonly
     * @type {string}
     */
    namespace = null

    /**
     * @param {T} data 
     */
    constructor(defaultValue = [], namespace = null) {
        this.namespace = namespace
        this.data = defaultValue
        this.loadFromStorage()
    }

    get key() {
        if (this.namespace === null) {
            return null
        }
        return `__stored_${this.namespace}`
    }

    get() {
        return this.data
    }

    /** @param {T} data  */
    set(data) {
        this.data = data
    }

    /** @returns {T} */
    copy() {
        let stringifieed = JSON.stringify(this.data)
        return JSON.parse(stringifieed)
    }

    persist() {
        if (this.namespace === null) {
            return
        }
        let stringified = JSON.stringify(this.data)
        localStorage.setItem(this.key, stringified)
    }

    /** @private */
    loadFromStorage() {
        if (this.namespace === null) {
            return
        }
        let persisted = localStorage.getItem(this.key) || null
        if (persisted === null) {
            return
        }
        this.data = JSON.parse(persisted)
    }
}

/** @type {Map<string,Storage>} */
const storageMap = new Map()

/**
 * @template T
 * @param {string} namespace 
 * @param {string} version
 * @returns {Storage<T>}
 */
Storage.getStorage = (namespace, version = 'default') => {
    const key = `${namespace}-${version}`
    if (!storageMap.has(key)) {
        storageMap.set(key, new Storage([], key))
    }
    return storageMap.get(key)
}

export default Storage