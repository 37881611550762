import { useSelector } from 'react-redux'
import { fileSelectors } from '../store/files'
import QueuedFile from '../components/QueuedFile'

const UploadQueue = () => {

    const isQueueActive = useSelector(fileSelectors.isQueueActive)
    const files = useSelector(fileSelectors.queued)



    const classes = [
        'module',
        'upload-queue'
    ]
    if (isQueueActive) {
        classes.push('upload-queue--hidden')
    }
    return (
        <div className={classes.join(' ')}>
            <div className="upload-queue__items">
                {files.map((file, index) => (
                    <QueuedFile
                        key={file.id}
                        name={file.name}
                        extension={file.extension}
                        format={file.type}
                        size={file.size}
                        active={index === 0} />
                    ))}
            </div>
        </div>
    )

}

export default UploadQueue