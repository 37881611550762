import { useDispatch } from 'react-redux'
import { appActions, appSelectors } from './store/app'
import { useSelector } from 'react-redux'
import Loading from './components/Loading'

const Root = (props) => {
    const { children } = props
    const dispatch = useDispatch()
    const isLoaded = useSelector(appSelectors.isLoaded)
    if (!isLoaded) {
        dispatch(appActions.initFetch())
    }

    return isLoaded ? children : <Loading />
}

export default Root