import React, { useEffect, useRef } from 'react'
import Dropzone from 'dropzone'
import { useDispatch } from 'react-redux'
import { fileActions } from '../store/files'
import { supportedFileFormats } from '../config'

const acceptedFiles = [
    'image/png',
    'image/jpeg',
    'audio/mpeg',
    'audio/ogg',
    '.psd'
]

const UploadFile = () => {

    let dispatch = useDispatch()

    let dropzoneRef = useRef()

    const handleFiles = (files) => {
        // TODO: add file size limitations
        dispatch(fileActions.enqueueFiles({ files }))
    }

    useEffect(() => {
        let dropzone = new Dropzone(dropzoneRef.current, {
            url: '/',
            uploadMultiple: false,
            autoQueue: false,
            disablePreviews: true,
            acceptedFiles: acceptedFiles.join(',')
        })

        dropzone.on('addedfiles', handleFiles)
        
        return () => dropzone.destroy()
    }, [])

    return (
        <div className="module upload-file">
            <div ref={dropzoneRef} className="upload-file__dropzone">
                <div className="upload-file__upload_text">
                    Drop files here <b>or</b> Click to upload
                </div>
                <p>Supported formats</p>
                <div className="upload-file__supported-formats">
                    {supportedFileFormats.map(format => (
                        <div key={format.type + format.extension} className={`upload-file__format file-bg--${format.type}`}><span>{format.extension.toUpperCase()}</span></div>
                    ))}
                </div>
            </div>
        </div>
    )

}

export default UploadFile