import React, { forwardRef } from 'react'

/**
 * @callback EditableTextChangeEvent
 * @param {React.FocusEvent<HTMLInputElement, Element>} e
 */

/**
 * @typedef EditableTextProps
 * @property {string} defaultValue
 * @property {boolean} disabled
 * @property {string} placeholder
 * @property {EditableTextChangeEvent} onChange
 */

/**
 * @param {EditableTextProps} param0 
 */
const EditableText = forwardRef(({
    defaultValue = '',
    disabled,
    placeholder,

    onChange = () => {}
}, ref) => {

    const handleBlur = (e) => {
        if (typeof onChange !== 'function') {
           return
        }
        if (defaultValue === e.target.value) {
            return
        }
        onChange(e)
    }

    return (
        <div className="component editable-text">
            <input disabled={disabled} ref={ref} placeholder={placeholder} onBlur={e => handleBlur(e)} defaultValue={defaultValue} />
        </div>
    )

})

export default EditableText