import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { menuItems } from '../config'
import { useSelector } from 'react-redux'
import { projectSelectors } from '../store/projects'

const createItem = (item = {}, isActive = false) => {
    const { key, label, icon, path } = item
    return (
        <Link key={key} to={path}>
            <li className={isActive ? 'menu-item menu-item--active' : 'menu-item'}>
                <span className="icon icon--small menu-item__icon"><i className={icon || 'bi bi-arrow-right-short'}></i></span>
                <span className="menu-item__label">{label}</span>
            </li>
        </Link>
    )
}

const MainMenu = () => {
    const location = useLocation()
    const hasProject = useSelector(projectSelectors.hasProject)

    const sectionClasses = [ 'main-menu__section' ]
    if (!hasProject) {
        sectionClasses.push('main-menu__hidden')
    }

    return (
        <div className="module main-menu">
            {menuItems.map(section => (
                <div key={section.key} className={sectionClasses.join(' ')}>
                    <div className="main-menu__title">{section.label}</div>
                    <ul className="main-menu__items">
                        {section.items.map(item => {
                            const isActive = location.pathname === item.path
                            return createItem(item, isActive)
                        })}
                    </ul>  
                </div>
            ))}
        </div>
    )

}

export default MainMenu