import { configureStore } from '@reduxjs/toolkit'
import files from './files'
import projects from './projects'
import app from './app'
import packs from './packs'

export const createStore = () => {
    return configureStore({
        reducer: {
            app: app.reducer,
            files: files.reducer,
            projects: projects.reducer,
            packs: packs.reducer
        }
    })
}

/**
 * @typedef State
 * @type {typeof createStore extends () => import('@reduxjs/toolkit/dist/configureStore').ToolkitStore<infer U> ? U : any}
 */

