import DashboardLayout from '../layout/DashboardLayout'
import PackList from '../modules/PackList'

const PacksPage = () => {

    return (
        <DashboardLayout>
            <PackList />
        </DashboardLayout>
    )

}

export default PacksPage