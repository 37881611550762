import { Modal } from '@webgame-cloud/react-components'
import CreatePackModal from './modals/CreatePackModal'

const Modals = () => {
    return (
        <Modal.ModalRender>
            <Modal.Window key="create-pack" element={<CreatePackModal />} />
        </Modal.ModalRender>
    )
}

export default Modals