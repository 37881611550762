import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import { Modal } from '@webgame-cloud/react-components'


const WelcomePage = () => {
    
    const openTestModal = Modal.useModalOpen('test')
    const openSecondModal = Modal.useModalOpen('second')
    
    return (
        <DashboardLayout pageTitle='Dashboard'>
            <div onClick={e => openSecondModal('test input 2')}>OPEN SECOND MODAL</div>
            <div onClick={e => openTestModal('test input')}>OPEN TEST MODAL</div>
            welcome to the application
        </DashboardLayout>
    )
}

export default WelcomePage