import Project from '../../models/Project'

export const getState = () => {

    return {

        /** @type {string} */
        current: null,

        /** @type {Array<Project>} */
        list: []

    }

}


export const selectors = {

    /** @param {import('../index').State} state */
    hasProject: state => {
        const currentProject = selectors.currentProject(state)
        return currentProject !== null
    },

    /** @param {import('../index').State} state */
    currentProject: state => {
        if (state.projects.current === null) {
            return null
        }
        let found =  state.projects.list.find(project => {
            return project.id === state.projects.current
        }) || null
        return found
    },

    /** @param {import('../index').State} state */
    projects: state => state.projects.list

}