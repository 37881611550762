import React from 'react'

/**
 * @typedef ModalProps
 * @property {string} className
 * @property {JSX.Element} element
 */

/** @param {ModalProps} props */
const Window = (props) => {

    const { element, className = '' } = props

    const classes = [
        className,
        'modals',
        'modals__window'
    ].join(' ')

    /** @type {import('react').MouseEventHandler<HTMLDivElement>} e */
    const handleClick = e => {
        e.stopPropagation()
        e.preventDefault()        
    }

    return (
        <div onClick={handleClick} className={classes}>
            {element}
        </div>
    )

}

export default Window