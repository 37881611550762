import updateObject from '../helpers/updateObject'

class FileCategory {

    /** @param {Partial<FileCategory>} data  */
    constructor(data) {
        updateObject(this, data)
    }

    /** @type {string} */
    id = null

    /** @type {string} */
    createdAt = null

    /** @type {string} */
    name = null

}

export default FileCategory