import React from 'react'
import Project from '../models/Project'

/**
 * @callback OnSelectCallback
 * @param {Project} project
 */

/**
 * @typedef ProjectDropdownProps
 * @property {Array<Project>} list
 * @property {Project | null} selected
 * @property {OnSelectCallback} onSelect
 */

/**
 * @typedef ProjectDropdownItemProps
 * @property {string} label
 * @property {import('react').MouseEventHandler<HTMLLIElement>} onClick
 */


/**
 * @param {ProjectDropdownItemProps} props 
 */
export const ProjectDropdownItem = (props) => {
    return (
        <li onClick={props.onClick} className="dropdown-item dropdown-item__small">
            <span className="project-dropdown__label">{props.label || '[LABEL_HERE]'}</span>
        </li>
    )
}

/** @param {ProjectDropdownProps} props */
const ProjectDropdown = (props) => {
    
    const {
        children,
        list = [],
        selected = null,
        onSelect = () => {}
    } = props
    
    console.log(list)
    const menuItems = list.filter(item => item.id !== selected.id)
    const hasItems = list.length > 0

    const classes = [ 'project-dropdown__button' ]
    if (hasItems) {
        classes.push('dropdown-enabled')
    }
    if (selected !== null) {
        classes.push(`border--${selected.color}`)
    }
    

    return selected !== null ? (
        <div className="component project-dropdown">
            <div className={classes.join(' ')} data-bs-toggle="dropdown" aria-expanded="true">
                <span className={`icon icon--large icon--genre-${selected.icon}`}></span>
                <span className="project-dropdown__label">{selected.name}</span>
            </div>
            {hasItems ? (
                <ul className="dropdown-menu project-dropdown__dropdown" data-popper-placement="bottom-start">
                    {menuItems.map(project => (
                        <li onClick={e => onSelect(e, project.id)} key={project.id} className="dropdown-item">
                            <span className={`icon icon--large icon--genre-${project.icon}`}></span>
                            <span className="project-dropdown__label">{project.name}</span>
                        </li>
                    ))}
                    {children}
                </ul>
            ): <></>}
        </div>
    ): (<></>)
}

export default ProjectDropdown