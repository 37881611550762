/**
 * @template T
 * @param {T} target 
 * @param {Partial<T>} data 
 */
const updateObject = (target, data) => {
    if (data === null) {
        return false
    }
    if (typeof data !== 'object') {
        return false
    }
    for (let key in data) {
        if (typeof target[key] !== 'undefined') {
            target[key] = data[key]
        }
    }
    return true
}

export default updateObject