const PackItem = () => {
    return (
        <div className="component pack-item">
            <h2 className="pack-item__title">Pack item 1</h2>
            <div className="pack-item__heading">Selectors</div>
            <div className="pack-item__heading">Settings</div>
        </div>
    )
}

const PackItemEmpty = ({ text = '', onClick }) => {
    return (
        <div onClick={onClick} className="component pack-item pack-item--empty">
            <div className="pack-item__center-text">{text}</div>
        </div>
    )
}

PackItem.Empty = PackItemEmpty

export default PackItem