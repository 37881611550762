import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'
import UploadFile from '../modules/UploadFile'
import FileList from '../modules/FileList'
import UploadQueue from '../modules/UploadQueue'


const FilesPage = () => {
    return (
        <DashboardLayout>
            <UploadFile />
            <UploadQueue />
            <FileList />
        </DashboardLayout>
    )
}

export default FilesPage