import React, { useEffect, useRef } from 'react'
import { Modal } from '@webgame-cloud/react-components'
import File from '../components/File'
import { useSelector } from 'react-redux'
import { fileActions, fileSelectors } from '../store/files'
import { useDispatch } from 'react-redux'

const CreatePackModal = () => {

    const dispatch = useDispatch()

    const closeModal = Modal.useModalClose()

    const nameEl = useRef()
    const categorySelectEl = useRef()

    const handleSubmit = () => {
        const payload = {
            name: nameEl.current.value
        }
        closeModal(payload)
    }

    useEffect(() => {
        dispatch(fileActions.fetchFiles())
    }, [])

    const categories = useSelector(fileSelectors.categories)
    console.log(categories)

    return (
        <div className="modal-window create-pack">
            <h1 className="create-pack__title">Create pack</h1>
                <div className="row">
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit} className="data-form">
                            <div className="data-form__section">
                                <label>Pack name</label>              
                                <input ref={nameEl} placeholder="" />
                                <p>I'm sorry there is an error</p>
                            </div>

                            <div className="data-form__section">
                                <label>Category</label>
                                <select ref={categorySelectEl} placeholder="select purpose">
                                    <option value={null}>All</option>
                                    {categories.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <div className="create-pack__file-list">
                            <File readonly name="magnarog_front" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="data-form__section">
                        <button onClick={handleSubmit} className="secondary" type="submit">Create</button>
                    </div>
                </div>
        </div>
    )

}

export default CreatePackModal