import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { colors, projectIcons } from '../config'
import { useDispatch } from 'react-redux'
import { createProject } from '../store/projects/actions'

const CreateProject = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const nameEl = useRef()
    const purposeEl = useRef()

    const [ selectedIcon, setSelectedIcon ] = useState(projectIcons[0])
    const [ selectedColor, setSelectedColor ] = useState(colors[0])

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(createProject({
            name: nameEl.current.value,
            purpose: purposeEl.current.value,
            icon: selectedIcon,
            color: selectedColor
        }))
        navigate('/')
    }

    return (
        <div className="module create-project">
            
            <form onSubmit={handleSubmit} className="data-form">
                <h1 className="create-project__title">Create project</h1>

                <div className="data-form__section">
                    <label>Name your project</label>              
                    <input ref={nameEl} placeholder="" />
                    <p>I'm sorry there is an error</p>
                </div>

                <div className="data-form__section">
                    <label>Tell us what it's for</label>
                    <select ref={purposeEl} placeholder="select purpose">
                        <option value=""></option>
                        <option value="trying">Just trying out webgame.cloud</option>
                        <option value="prototyping">Prototyping a game</option>
                        <option value="migrating">Migrating to webgame.cloud</option>
                    </select>
                </div>

                <h2>Select icon</h2>
                <div className="create-project__icon-list">
                    {projectIcons.map(key => {
                        const iconClasses = [
                            'icon', 'icon--huge', `icon--genre-${key}`
                        ]

                        const markSelected = selectedIcon === key
                        const classes = [ 'create-project__icon' ]
                        if (markSelected) {
                            classes.push('create-project__icon--selected')
                        }
                        return (
                            <div key={key} onClick={e => setSelectedIcon(key)} className={classes.join(' ')}>
                                <span className={iconClasses.join(' ')}></span>
                            </div>
                        )
                    })}
                </div>
                <h2>Select color</h2>
                <div className="create-project__color-list">
                    {colors.map(name => {
                        const markSelected = selectedColor === name
                        const classes = [ 'create-project__color', `bg--${name}` ]
                        if (markSelected) {
                            classes.push('create-project__color--selected')
                        }
                        return (
                            <div key={name} onClick={e => setSelectedColor(name)} className={classes.join(' ')}></div>
                        )
                    })}
                </div>
                <div className="data-form__section">
                    <button className="secondary" type="submit">Create</button>
                </div>
            </form>
            
        </div>
    )

}

export default CreateProject