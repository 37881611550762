import { createSlice } from '@reduxjs/toolkit'
import { getState, selectors } from './state'
import * as async from './actions'

const projects = createSlice({
    name: 'projects',
    initialState: getState,
    reducers: {
        
    },
    extraReducers: builder => {

        builder.addCase(async.fetchProjects.fulfilled, (state, action) => {
            const { list = [], current = null } = action.payload
            state.list = list
            state.current = current
        })

    }
})

const actions = {
    ...async,
    ...projects.actions
}

export {
    selectors as projectSelectors,
    actions as projectActions
}

export default projects