import React from 'react'

const Loading = (props) => {
    return (
        <div className="component loading">
            <div className="loading__base">Loading...</div>
        </div>
    )
}

export default Loading