import React, { useRef } from 'react'
import FileTag from '../models/FileTag'
import toSlug from '../helpers/toSLug'

/**
 * @callback HandleActionCallback
 * @param {React.MouseEvent<HTMLElement,MouseEvent>} e
 * @param {string} actionId
 * @param {any} payload
 */

/**
 * @typedef FileTagsProps
 * @property {boolean} readonly
 * @property {Array<FileTag>} list
 * @property {Array<string>} selectedIds
 * @property {HandleActionCallback} handleAction
 */

/** @param {FileTagsProps} props */
const FileTags = (props) => {

    const {
        readonly = false,
        list = [],
        selectedIds = [],
        handleAction = () => {}
    } = props

    const addedTags = []
    const freeTags = []
    for (let tag of list) {
        if (selectedIds.includes(tag.id)) {
            addedTags.push(tag)
        } else {
            freeTags.push(tag)
        }
    }

    const listedTags = [ ...freeTags ]

    const tagCreateEl = useRef()
    const tagInputEl = useRef()

    
    const handleTagCreateClick = () => {
        /** @type {HTMLInputElement} */
        let input = tagInputEl.current
        input.focus()
    }
    
    const handleTagCreateOnBlur = () => {
        /** @type {HTMLInputElement} */
        let input = tagInputEl.current
        input.value = ''
    }

    const handleSubmit = e => {
        e.preventDefault()
        /** @type {HTMLInputElement} */
        const input = tagInputEl.current
        /** @type {HTMLSpanElement} */
        const button = tagCreateEl.current

        const name = toSlug(input.value)
        const tag = list.find(tag => tag.name === name) || null
        if (tag === null) {
            handleAction(e, 'create_tag', name)
        } else {
            handleAction(e, 'add_tag', tag.id)
        }
        
        input.blur()
        button.click()
    }

    return (
        <div className="component file-tags">
            {addedTags.map(tag => (
                <span
                    key={tag.id}
                    className="badge badge--tag"
                    onClick={e => handleAction(e, 'delete_tag', tag.id)}>
                        {tag.name}
                </span>
            ))}
            <span style={{ visibility: readonly ? 'hidden' : 'visible' }} ref={tagCreateEl} onClick={handleTagCreateClick} className="badge badge--add" data-bs-toggle="dropdown" aria-expanded="false">+</span>
            <ul className="dropdown-menu dropdown-menu--small">
                
                <div className="file-tags__create">
                    <form onSubmit={handleSubmit}>
                        <input ref={tagInputEl} onBlur={handleTagCreateOnBlur} placeholder="create tag..." defaultValue="" />
                        <span className="file-tags__create-hint">&#8629;</span>
                    </form>
                </div>

                {listedTags.map(tag => (
                    <li key={tag.id}>
                        <a className="dropdown-item" onClick={e => handleAction(e, 'add_tag', tag.id)}>
                            {tag.name}
                        </a>
                    </li>
                ))}
                
            </ul>
        </div>
    )

}

export default FileTags