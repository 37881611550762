import React from 'react'
import { useSelector } from 'react-redux'
import { projectSelectors } from '../store/projects'

const ProjectUsage = () => {

    const hasProject = useSelector(projectSelectors.hasProject)

    if (!hasProject) {
        return (<></>)
    }

    return (
        <div className="module project-usage">
            <div className="project-usage__block">
                <h3>Storage</h3>
                <p><b>50 MB</b> / 500 MB - <b>10%</b> used</p>
                <div className="progress">
                    <div className="progress-bar" style={{ width: '10%' }}></div>
                </div>
            </div>
            <div className="project-usage__block">
                <h3>Bandwidth</h3>
                <p><b>0.6 GB</b> / 25 GB - <b>2%</b> used</p>
                <div className="progress">
                    <div className="progress-bar" style={{ width: '2%' }}></div>
                </div>
            </div>
        </div>
    )

}

export default ProjectUsage