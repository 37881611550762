import updateObject from '../helpers/updateObject'

class FileItem {

    /** @param {Partial<FileItem>} data  */
    constructor(data) {
        updateObject(this, data)
    }

    /** @type {string} */
    id = null

    /** @type {string} */
    categoryId = null

    /** @type {Array<string>} */
    tagIds = []

    /** @type {string} */
    createdAt = null

    /** @type {string} */
    name = null

    /** @type {string} */
    type = null

    /** @type {string} */
    extension = null

    /** @type {string} */
    mime = null

    /** @type {number} */
    size = 0

    /** @type {Array<FileItem>} */
    childs = []

}

export default FileItem