import {  createAsyncThunk } from '@reduxjs/toolkit'
import FileService from '../../services/FileService'
import config from '../../config'

export const fetchFiles = createAsyncThunk(
    'files/fetch',
    (_, { getState }) => {
        const projectId = getState().projects.current
        return FileService.Instance.fetch(projectId)
    }
)

export const getQueue = createAsyncThunk(
    'files/queue/get',
    () => {
        const files = FileService.Instance.getQueue()
        return files
    }
)

export const enqueueFiles = createAsyncThunk(
    'files/queue/add',
    async (args = {}, { dispatch, getState }) => {
        FileService.Instance.enqueueFiles(args.files)
        await dispatch(getQueue())
        let isQueueActive = getState().files.totalQueuedFiles !== null
        if (!isQueueActive) {
            dispatch(uploadFiles())
        }
        return args.files.length
    }
)

export const uploadFiles = createAsyncThunk(
    'files/upload',
    async (_, { dispatch }) => {
        await dispatch(getQueue())
        let lastUpdate = Date.now()
        while(FileService.Instance.hasQueuedFiles()) {
            if (lastUpdate + config.timeBetweenFetchRequests < Date.now()) {
                lastUpdate = Date.now()
                dispatch(fetchFiles())
            }
            await dispatch(uploadQueuedFile())
        }
        dispatch(fetchFiles())
    }
)

export const uploadQueuedFile = createAsyncThunk(
    'files/upload_file',
    async (_, { dispatch, getState }) => {
        const projectId = getState().projects.current
        try {
            await FileService.Instance.uploadQueuedFile(projectId)
        } catch (error) {
            console.error(error)
        }
        await dispatch(getQueue())
    }
)

export const deleteFiles = createAsyncThunk(
    'files/delete',
    async (args = { id }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.deleteFiles(projectId, args.id)
        dispatch(fetchFiles())
    }
)

export const updateFiles = createAsyncThunk(
    'files/update',
    async (args = { id: null, data: {} }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.updateFiles(projectId, args.id, args.data)
        dispatch(fetchFiles())
    }
)

export const createCategory = createAsyncThunk(
    'files/category/create',
    async (args = { name: '' }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.createCategory(projectId, args.name)
        dispatch(fetchFiles())
    }
)

export const deleteCategories = createAsyncThunk(
    'files/category/delete',
    async (args = { id }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.deleteCategories(projectId, args.id)
        dispatch(fetchFiles())
    }
)

export const updateCategories = createAsyncThunk(
    'files/category/update',
    async (args = { id, data }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.updateCategories(projectId, args.id, args.data)
        dispatch(fetchFiles())
    }
)


export const createTag = createAsyncThunk(
    'files/tag/create',
    async (args = { name: '', refId: null }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.createTag(projectId, args.name, args.refId)
        dispatch(fetchFiles())
    }
)

export const deleteTags = createAsyncThunk(
    'files/tag/delete',
    async (args = { id }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.deleteTags(projectId, args.id)
        dispatch(fetchFiles())
    }
)

export const updateTags = createAsyncThunk(
    'files/tag/update',
    async (args = { id, data }, { dispatch, getState }) => {
        const projectId = getState().projects.current
        await FileService.Instance.updateTags(projectId, args.id, args.data)
        dispatch(fetchFiles())
    }
)

