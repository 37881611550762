import React from 'react'
import { formatByteSize } from '@toolcase/base'
import EditableText from './EditableText'
import FileCategory from '../models/FileFolder'
import FileTag from '../models/FileTag'
import FileFolders from './FileFolders'
import FileTags from './FileTags'

/**
 * @callback ActionEvent
 * @param {string} fileId
 * @param {string} actionId
 * @param {any} payload
 */

/**
 * @typedef FileProps
 * @property {string} id
 * @property {boolean} readonly
 * @property {('unknown'|'image'|'audio'|'binary')} format
 * @property {string} extension
 * @property {string} name
 * @property {number} items
 * @property {string} createdAt
 * @property {number} size
 * @property {string} categoryId
 * @property {Array<string>} tagIds
 * @property {Array<FileCategory>} categoryList
 * @property {Array<FileTag>} tagList
 * @property {ActionEvent} onAction
 */


/**
 * 
 * @param {FileProps} param0 
 */
const File = ({
    id = '[ID_NOT_PROVIDED]',
    readonly = false,
    format = 'unknown',
    extension = '_',
    name = '[FILE_NAME]',
    items = 0,
    size = 2000000,
    folderId = null,
    folderList = [],
    tagIds = [],
    tagList = [],
    onAction = () => {}
}) => {
        
    const handleAction = (e, actionId, payload = null) => {
        e.preventDefault()
        if (typeof onAction === 'function') {
            onAction(id, actionId, payload)
        }
    }
    
    return (
        <div className="component file">
            <div className={`file__type file-bg--${format}`}><span>{extension}</span></div>
            <div className="file__label">
                <EditableText disabled={readonly} defaultValue={name} onChange={e => handleAction(e, 'update_name', e.target.value)} />
                <div className="file__label__lists">
                    <FileFolders readonly={readonly} list={folderList} selectedId={folderId} handleAction={handleAction} />
                    <FileTags readonly={readonly} list={tagList} selectedIds={tagIds} handleAction={handleAction} />
                </div>
            </div>
            <div className="file__items">{(items != 0) ? (
                <a href="#">
                    <span>{items}</span>
                    <span className="icon icon--medium">
                        <i className="bi bi-file-binary"></i>
                    </span>
                </a>
            ) : <></>}</div>
            <div className="file__size">{formatByteSize(size)}</div>
            {readonly ? (<></>) : (
                <div className="file__menu">
                    
                </div>
            )}
        </div>
    )

}

export default File

