import React from 'react'
import ProjectDropdown, { ProjectDropdownItem } from '../components/ProjectDropdown'
import { useSelector } from 'react-redux'
import { projectActions, projectSelectors } from '../store/projects'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const ProjectMenu = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const projectList = useSelector(projectSelectors.projects)
    const currentProject = useSelector(projectSelectors.currentProject)
    const hasProjects = projectList.length > 0

    const handleSelect = async (_, projectId) => {
        dispatch(projectActions.selectProject({ id: projectId }))
    }

    const handleCreate = () => {
        // TODO: disable create button if limit is exceeded
        navigate('/create')
    }

    const handleManageMembers = () => {
        navigate('/members')
    }

    const handleProjectSettings = () => {
        navigate('/settings')
    }



    return (
        <div className="module project-menu">
            <ul className="project-menu__items" style={{ display: hasProjects ? 'none' : 'block' }}>
                <li className="menu-item menu-item--disabled">
                    <span className="icon icon--small menu-item__icon">
                        <i className="bi bi-plus-circle"></i>
                    </span>
                    <span onClick={handleCreate} className="menu-item__label">Create new project</span>
                </li>
            </ul>
            <ProjectDropdown list={projectList} selected={currentProject} onSelect={handleSelect}>
                <ProjectDropdownItem label="Create new project" onClick={handleCreate} />
            </ProjectDropdown>
            <ul className="project-menu__items" style={{ display: hasProjects ? 'block' : 'none' }}>
                <li className="menu-item menu-item--disabled">
                    <span className="icon icon--small menu-item__icon">
                        <i className="bi bi-people"></i>
                    </span>
                    <span onClick={handleManageMembers} className="menu-item__label">Members</span>
                </li>
                <li className="menu-item menu-item--disabled">
                    <span className="icon icon--small menu-item__icon">
                        <i className="bi bi-gear"></i>
                    </span>
                    <span onClick={handleProjectSettings} className="menu-item__label">Settings</span>
                </li>
            </ul>
        </div>
    )

}

export default ProjectMenu