import { createRoot } from 'react-dom/client'
import * as bootstrap from 'bootstrap'

import Router from './Router'

window.addEventListener('DOMContentLoaded', () => {
    const rootElement = document.getElementById('root')
    console.log(rootElement)
    try {
        const root = createRoot(rootElement)
        root.render(<Router />)
    } catch (error) {
        console.error('tuka e makata', error)
    }
})

export { bootstrap }