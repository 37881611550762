import React from 'react'
import { useNavigate } from 'react-router-dom'

const Logo = () => {

    const navigate = useNavigate()

    const handleClick = e => {
        navigate('/')
    }

    return (
        <div className="module logo">
            <h1 onClick={handleClick} className="logo__text">
                webgame<span>cloud</span>
            </h1>
        </div>
    )

}

export default Logo