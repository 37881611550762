import React from 'react'
import DashboardLayout from '../layout/DashboardLayout'

const NotFoundPage = () => {
    return (
        <DashboardLayout>
            not found
        </DashboardLayout>
    )
}

export default NotFoundPage